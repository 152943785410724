import React from "react";
import { 
  Seo, 
  Link, 
  Section, 
  Container, 
} from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";
import { RightSidebar } from "../../components/site/product";

import { StaticImage } from "gatsby-plugin-image";
import "./custom_breakpoints.css";
const MobilityMarketPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <div className="bg-[#122331] w-full">
        <Section>
          <Container className="py-[100px] md:py-[30px]">
            <div className="md:flex justify-between items-center">
              <div className="w-full md:w-[450px]">
                <h1 className="w-fit m-auto text-[40px] leading-[50px] md:ml-0 md:text-[53px] md:leading-[70px] font-semibold text-center text-[#ffffff] border-b-[6px] border-[#B21C7A] pb-[18px]">
                  Industries
                </h1>
              </div>
              <div className="w-full md:w-1/2 mt-[58px] md:mt-0">
                <StaticImage
                  src={`../../components/site/markets/images/industries.png`}
                  className="w-full md:max-w-[593px] h-auto"
                  alt="Mobile App and API Protection | Approov"
                  objectFit="contain"
                />
              </div>
            </div>
          </Container>
        </Section>
      </div>

      <Section>
        <Container className="pt-[20px] pb-[50px] md:pt-[60px] md:pb-[70px] md:flex justify-between">
          <div className="md:w-full md:mr-[38px]">
            <h6 className="text-[#bd2a7b] font-bold text-[30px] my-0">MOBILITY</h6>
            <div className="lg:flex justify-between lg:flex-row-reverse">
              <StaticImage
                src={`../../components/site/images/mobility.png`}
                className="w-full lg:max-w-[350px] max-h-[365px] lg:max-h-fit h-auto rounded-[10px] mt-[20px] lg:mt-0"
                alt="message"
                objectFit="cover"
              />
              <div className="w-full lg:mr-[35px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] my-0 mt-[40px] lg:mt-0">
                  Pay-Per-Use Models are Growing
                </h2>
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  The transportation mobility market is shifting consumers from traditional vehicle ownership to a
                  pay-per-use model. Mobile apps are the primary touchpoint for accessing these services and providing new
                  business opportunities. Failure to properly secure APIs leads to system breaches, unauthorized access to
                  private data, and ultimately loss of customers.
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Own Customer Experience
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  In a competitive mobility marketplace, customers flock to services offering convenience and
                  availability. Approov authenticates your apps and secures your APIs with no impact on customer
                  experience. Threats blocked include:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Data Scraping
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Competitors scrape and exploit your vehicle location and usage information to gain an advantage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Ensures that your data is accessible by your mobile apps only. Tampered apps and scraper bots are
                        blocked.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Unauthorized Aggregation
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Customers may give their credentials to all-in-one services offering convenience booking.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Forces these services to refer customers to your app so you retain control of your customer
                        journey.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] lg:flex justify-between">
              <StaticImage
                src={`../../components/site/images/mobility-six.png`}
                className="w-full lg:max-w-[390px] h-[365px] h-auto rounded-[10px]"
                alt="message"
                objectFit="cover"
              />
              <div className="w-full lg:ml-[35px] pt-[20px]">
                <div className="lg:text-[17px] lg:leading-[29px] text-black">
                  Learn how Sixt blocks third parties from exploiting APIs to reserve and access cars.
                </div>
                <Link href="/customer/sixt" className="!no-underline">
                  <div className="text-white bg-[#78A65A] mt-[25px] px-[38px] py-[16px] rounded-[7px] font-medium text-[16px] leading-[28px] text-center md:w-fit transition hover:bg-[#1b7609]">
                    Sixt's Customer Story
                  </div>
                </Link>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Protect Your Critical Resources
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  Car sharing is designed for consumers who want to rent a vehicle for a short period of time, pay
                  only for usage, and access a car at any hour. Approov Mobile App Protection is 'key' to this
                  model:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Digital Key Extraction
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Eavesdroppers or malware running on consumer devices try to intercept key authorization during the
                        communication process used to unlock and start vehicles.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Blocks malicious exfiltration attempts, but allows consumers running a clean, authentic app to
                        access their vehicles even in the absence of internet connectivity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Denial or Delay of Service
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Attackers can flood your network with valid looking API requests that will tie up your services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Authenticates apps ensuring that legitimate API requests come only from your mobile app. Malicious
                        traffic is easily dropped before reaching your backend services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-[50px] md:mt-[40px] bg-[#f2f5f9] rounded-[10px] py-[30px] px-[20px] lg:py-[40px] lg:px-[50px]">
              <div className="mb-[25px]">
                <h2 className="text-[25px] lg:text-[31px] font-semibold text-[#1B3D57] text-left lg:text-center my-0">
                  Grow Safely Within the Mobility Ecosystem
                </h2>
                <div className="bg-[#B21C7A] h-[4px] mt-[10px] w-[162px] lg:mt-[15px] lg:w-[230px] lg:mx-auto"></div>
                <div className="text-[#262727] text-[17px] mt-[10px] leading-[29px] font-medium lg:mt-[15px]">
                  The transportation market is transforming, the ecosystem is maturing, and your partnerships are
                  evolving through shared-use models, autonomous vehicles, door-to-door multi-modal journeys —
                  making API security all the more critical. Threats include:
                </div>
              </div>
              <div className="w-full block flex-wrap lg:flex mt-[16px] md:mt-[50px]">
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    Unlicensed Apps
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        When ready, expand the number of legitimate apps that can access your mobility services by
                        licensing third-party apps while continuing to block all others.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-0">
                  <p className="leading-[25px] text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Authenticates licensed apps and drops all other traffic.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pr-[15px] pt-[10px] lg:pt-[20px] lg:border-r-2 border-[#e2e2e2]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#B21C7A] not-prose text-left lg:text-right">
                    API Security Flaws
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left lg:text-right not-prose pt-[5px]">
                        Any weaknesses in your APIs will be discovered and exploited by hackers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-[100%] not-prose lg:pl-[15px] pt-[10px] lg:pt-[20px]">
                  <p className="text-[17px] leading-[29px] font-bold text-[#78A65A] not-prose text-left">
                    Approov Solution
                  </p>
                  <div className="lg:block flex">
                    <div>
                      <p className="text-[16px] leading-[27px] text-[#262727] text-left not-prose pt-[5px]">
                        Blocks API probing and improper usage by securing communications between your app and backend
                        service in addition to locking down your mobility APIs to authorized apps only.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[50px] md:mt-0 md:max-w-[280px]">
            <RightSidebar />
          </div>
        </Container>
      </Section>
    </PageLayout>
  );
};

export default MobilityMarketPage;
